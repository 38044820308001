import { template as template_a9a017df44e2478b8726af68e2d861de } from "@ember/template-compiler";
const SidebarSectionMessage = template_a9a017df44e2478b8726af68e2d861de(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
